import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Input, { Size as InputSize } from '@jetbrains/ring-ui/components/input/input';
import { Required } from '@common/form/important-asterisk';
import RingFieldsList from '@common/form/ring-fields-list';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { GoogleAiModelsTable } from './google-ai-models-table';
const TOKEN_EXISTENCE_INDICATOR = '⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱';
export function GoogleAiProviderForm({ form }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const { setValue, watch, formState: { errors } } = form;
    const token = watch('provider.google.token');
    return (_jsxs(RingFieldsList, { children: [_jsx(Input, { value: watch('provider.google.project'), label: Required('Project'), onChange: val => setValue('provider.google.project', val.target.value, {
                    shouldValidate: true,
                    shouldDirty: true
                }), size: InputSize.L, error: (_c = (_b = (_a = errors.provider) === null || _a === void 0 ? void 0 : _a.google) === null || _b === void 0 ? void 0 : _b.project) === null || _c === void 0 ? void 0 : _c.message }), _jsx(Input, { value: watch('provider.google.region'), label: Required('Region'), onChange: val => setValue('provider.google.region', val.target.value, {
                    shouldValidate: true,
                    shouldDirty: true
                }), size: InputSize.L, error: (_f = (_e = (_d = errors.provider) === null || _d === void 0 ? void 0 : _d.google) === null || _e === void 0 ? void 0 : _e.region) === null || _f === void 0 ? void 0 : _f.message }), _jsx(Input, { ...assignTestId('token-input'), value: watch('provider.google.token'), label: Required('Token'), placeholder: token === null ? TOKEN_EXISTENCE_INDICATOR : '', onChange: val => setValue('provider.google.token', val.target.value, {
                    shouldValidate: true,
                    shouldDirty: true
                }), type: "password", size: InputSize.L, error: (_j = (_h = (_g = errors.provider) === null || _g === void 0 ? void 0 : _g.google) === null || _h === void 0 ? void 0 : _h.token) === null || _j === void 0 ? void 0 : _j.message }), _jsx(GoogleAiModelsTable, { models: watch('provider.google.models') })] }));
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Text from '@jetbrains/ring-ui/components/text/text';
import { AI_PROVIDERS } from '@modules/ai/common/constants';
import styles from '../styles.css';
export function AiAvailableProviders({ form }) {
    const { getValues } = form;
    const availableProviders = getValues('availableProviders');
    return (_jsxs("div", { className: styles.availableProvidersContainer, children: [_jsx(Text, { size: "s", info: true, children: "Available providers" }), availableProviders === null || availableProviders === void 0 ? void 0 : availableProviders.map(item => {
                var _a;
                return (_jsx(Text, { children: (_a = AI_PROVIDERS.find(({ key }) => key === item)) === null || _a === void 0 ? void 0 : _a.label }, `provider-${item}`));
            })] }));
}

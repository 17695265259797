import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import Loader from '@jetbrains/ring-ui/components/loader/loader';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { AIDisabledIDES } from '@app/pages/profiles/profile-details/profile-ai/ai-disabled-ides/ai-disabled-ides';
import { AIAvailable } from '@app/pages/profiles/profile-details/profile-ai/ai-available/ai-available';
import { useAiConstituent } from '@app/common/queries/ai-enterprise';
import { getProfileConstituents } from '@app/common/store/profiles/selectors';
import auth from '@app/common/auth/auth';
import { ProfileActions } from '@common/store/profiles/profile-reducer';
import { AiDisabledJBA } from './ai-disabled-jba/ai-disabled-jba';
export function ProfileAIPage() {
    var _a, _b, _c;
    const offlineModeEnabled = !!auth.getFeatures().offlineModeEnabled;
    const { ai } = useSelector(getProfileConstituents);
    const dispatch = useDispatch();
    const setEnabledForProfile = useCallback((enabled) => dispatch(ProfileActions.updateAiConstituent({ isEnabled: enabled })), [dispatch]);
    const setAllowDataCollection = useCallback((allowed) => dispatch(ProfileActions.updateAiConstituent({ allowDataCollection: allowed })), [dispatch]);
    const { data: aiResponse, isLoading: isAiLoading } = useAiConstituent();
    if (offlineModeEnabled) {
        return _jsx(Navigate, { to: "provisioner" });
    }
    if (isAiLoading) {
        return _jsx(Loader, {});
    }
    if ((aiResponse === null || aiResponse === void 0 ? void 0 : aiResponse.status) === 'disabled_jba') {
        return _jsx(AiDisabledJBA, {});
    }
    if ((aiResponse === null || aiResponse === void 0 ? void 0 : aiResponse.status) === 'disabled') {
        return _jsx(AIDisabledIDES, {});
    }
    if ((aiResponse === null || aiResponse === void 0 ? void 0 : aiResponse.status) === 'enabled') {
        const availableProviders = [
            (_a = aiResponse.data.provider.jetbrains) === null || _a === void 0 ? void 0 : _a.id,
            (_b = aiResponse.data.provider.openai) === null || _b === void 0 ? void 0 : _b.id,
            (_c = aiResponse.data.provider.google) === null || _c === void 0 ? void 0 : _c.id
        ].filter((provider) => !!provider);
        return (_jsx(AIAvailable, { enabledForProfile: ai.isEnabled, allowDataCollection: ai.allowDataCollection, setEnabledForProfile: setEnabledForProfile, setAllowDataCollection: setAllowDataCollection, availableProviders: availableProviders, defaultProvider: aiResponse.data.defaultProvider }));
    }
    return null;
}

import z from 'zod';
const tGoogleAiModel = z.object({
    id: z.string(),
    modelName: z.string(),
    tokenLimit: z.number()
});
export const tGoogleAiFormSchema = z.object({
    token: z.string().min(5, 'Token is required').nullable(),
    id: z.string().optional(),
    project: z.string().nonempty('Project is required'),
    region: z.string().nonempty('Region is required'),
    models: z.array(tGoogleAiModel)
});

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import Toggle from '@jetbrains/ring-ui/components/toggle/toggle';
import { H3 } from '@jetbrains/ring-ui/components/heading/heading';
import Text from '@jetbrains/ring-ui/components/text/text';
import infoIcon from '@jetbrains/icons/info';
import { Size } from '@jetbrains/ring-ui/components/icon/icon';
import Button from '@jetbrains/ring-ui/components/button/button';
import { AiDataSharingMessage } from '@modules/ai/common/ai-data-sharing/ai-data-sharing';
import { ProviderSelector } from '../provider-selector/provider-selector';
import styles from './ai-available.css';
export function AIAvailable(props) {
    const { enabledForProfile, allowDataCollection, setEnabledForProfile, setAllowDataCollection, availableProviders, defaultProvider } = props;
    const [showDataCollectionMessage, setShowDataCollectionMessage] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsx("div", { children: _jsx(Toggle, { checked: enabledForProfile, onChange: e => setEnabledForProfile(e.target.checked), children: "Enable AI Enterprise for profile users" }) }), enabledForProfile && (_jsxs("div", { className: styles.settings, children: [_jsx(H3, { children: "Configuration" }), _jsxs("div", { className: styles.settingsForm, children: [_jsxs("div", { className: styles.toggleWrapper, children: [_jsx(Toggle, { checked: allowDataCollection, onChange: e => setAllowDataCollection(e.target.checked), children: "Allow detailed data collection" }), _jsxs("span", { children: [_jsx(Button, { icon: infoIcon, iconSize: Size.Size14, onMouseEnter: () => setShowDataCollectionMessage(true) }), showDataCollectionMessage && (_jsx(AiDataSharingMessage, { setShowDataCollectionMessage: setShowDataCollectionMessage }))] })] }), _jsx("div", { children: _jsx(Text, { info: true, size: "s", children: allowDataCollection ? (_jsx("span", { children: "Profile users will be able to change this setting in their IDEs" })) : (_jsxs("span", { children: ["Profile users will ", _jsx("strong", { children: "not" }), " be able to change this setting in their IDEs"] })) }) })] }), _jsx(ProviderSelector, { availableProviders: availableProviders, defaultProvider: defaultProvider })] }))] }));
}

import { z } from 'zod';
import { tGoogleAiFormSchema } from '../common/forms/google-ai-provider-form/google-ai-form-schema';
import { tOpenAiFormSchema } from '../common/forms/open-ai-provider-form/open-ai-form-schema';
export const tAiSettingsFormSchema = z.object({
    activeIdeProfilesCount: z.number(),
    activeUsersCount: z.number(),
    assignedUsersLimit: z.number().nullable().optional(),
    disabledByLimitsUsersCount: z.number().nullable(),
    provider: z.object({
        jetbrains: z
            .object({
            id: z.string()
        })
            .nullable()
            .optional(),
        openai: tOpenAiFormSchema.nullable().optional(),
        google: tGoogleAiFormSchema.nullable().optional()
    })
});

import { jsx as _jsx } from "react/jsx-runtime";
import { BasicTableView } from '@common/tables/basic-table-view';
import styles from '../styles.css';
const baseColumns = [
    {
        id: 'id',
        title: 'ID'
    },
    {
        id: 'modelName',
        title: 'Model name'
    }
];
export function GoogleAiModelsTable({ models }) {
    return (_jsx("div", { children: _jsx(BasicTableView, { tableWrapperClassName: styles.modelsTable, columns: baseColumns, data: models, selectable: false }) }));
}

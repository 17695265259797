import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import dataIcon from '@jetbrains/icons/data';
import Text from '@jetbrains/ring-ui/components/text/text';
import Message from '@jetbrains/ring-ui/components/message/message';
import NavigationLink from '@common/navigation/navigation-link';
import { ExternalLinks } from '@common/constants/constants';
export function AiDataSharingMessage(props) {
    const { setShowDataCollectionMessage } = props;
    return (_jsx(Message, { title: "Detailed Data Collection", popupProps: { onOutsideClick: () => setShowDataCollectionMessage(false) }, icon: dataIcon, children: _jsxs(_Fragment, { children: [_jsxs(Text, { size: "s", children: ["Detailed data collection includes full data about the interactions with large language models.", _jsx("br", {}), "This means the full text of inputs sent by the IDE to the language model and its responses, including source code snippets."] }), _jsx("p", { children: _jsx(NavigationLink, { href: ExternalLinks.JB_AI_ENTERPRISE_DETAILED_DATA_COLLECTION, target: "_blank", children: "Learn more" }) })] }) }));
}

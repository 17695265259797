import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import { Content } from '@jetbrains/ring-ui/components/island/island';
import { FormProvider, useForm } from 'react-hook-form';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import { zodResolver } from '@hookform/resolvers/zod';
import { isNil } from 'lodash';
import { useDialogCancelConfirmation } from '@app/common/hooks/use-dialog-cancel-confirmation';
import { tAutoInstalledPluginsSchema } from '../../schemas/auto-installed-plugins-schema';
import { getEmptyAutoInstalledPluginFormValues } from '../../schemas/ctors';
import SettingFiltersField from '../../common/setting-filters-field';
import DialogHeader from '../../common/dialog-header';
import { useUserPluginRestrictedMode } from '../../hooks/user-restricted-modes';
import ContentField from './fields/content-field';
import styles from './auto-installed-plugins-dialog-form.css';
function AutoInstalledPluginsDialogForm({ initialFormValues, onReject, onSubmit }) {
    var _a;
    const { isRestricted, restrictedPlugins } = useUserPluginRestrictedMode();
    const form = useForm({
        defaultValues: (_a = mapAutoinstalledToType(initialFormValues)) !== null && _a !== void 0 ? _a : getEmptyAutoInstalledPluginFormValues(),
        resolver: zodResolver(tAutoInstalledPluginsSchema(isRestricted, restrictedPlugins))
    });
    const { handleSubmit, formState: { isSubmitting, isDirty, isValid } } = form;
    const [isFetchingTools, setIsFetchingTools] = useState(false);
    const canSubmit = isDirty && !isFetchingTools && isValid;
    const { onCloseAttempt, cancelButtonRef } = useDialogCancelConfirmation(onReject, isDirty);
    const submit = (values) => {
        onSubmit(values);
    };
    return (_jsxs(Dialog, { label: "Configure Auto-Installed Plugins", show: true, autoFocusFirst: false, onCloseAttempt: onCloseAttempt, contentClassName: styles.dialogContent, children: [_jsx(DialogHeader, { children: "Configure Auto-Installed Plugins" }), _jsx(Content, { children: _jsx(FormProvider, { ...form, children: _jsxs("form", { onSubmit: handleSubmit(submit), className: styles.formContainer, children: [_jsx(SettingFiltersField, { onIsFetchingChange: setIsFetchingTools, disabled: isRestricted, disabledText: "Filter modification is disallowed in Restricted Mode" }), _jsx(ContentField, {})] }) }) }), _jsxs(Panel, { children: [_jsx(Button, { primary: true, onClick: handleSubmit(submit), type: "submit", loader: isSubmitting, disabled: !canSubmit, children: "Save" }), _jsx(Button, { ref: cancelButtonRef, onClick: onReject, children: "Cancel" })] })] }));
}
export default AutoInstalledPluginsDialogForm;
function mapAutoinstalledToType(initialValue) {
    if (!initialValue) {
        return undefined;
    }
    const contentWithTypes = initialValue.content.map(values => {
        return {
            ...values,
            type: !isNil(values.version) ? 'version' : 'channel'
        };
    });
    return { ...initialValue, content: contentWithTypes };
}

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import Loader from '@jetbrains/ring-ui/components/loader/loader';
import trashIcon from '@jetbrains/icons/trash';
import copyIcon from '@jetbrains/icons/copy';
import timeIcon from '@jetbrains/icons/time';
import { noop } from 'lodash';
import { Navigate, useLoaderData, useNavigate } from 'react-router-dom';
import Group from '@jetbrains/ring-ui/components/group/group';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import { PageTitle } from '@common/page-layout/page-title';
import { useDeletePluginMutation } from '@common/queries/plugins';
import { useOpenModal } from '@common/modals/hooks';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import ConfigureCustomChannelDialog from '../popups/configure-custom-channel-dialog';
import { confirmPluginDeletion } from '../popups/confirmations';
import PluginVersions from './plugin-versions';
import PluginInfo from './plugin-info/plugin-info';
export default function PluginPage() {
    const plugin = useLoaderData();
    const navigate = useNavigate();
    const deleteMutation = useDeletePluginMutation();
    const enableFetching = !(deleteMutation.isLoading || deleteMutation.isSuccess);
    const configureCustomChannel = useOpenModal(ConfigureCustomChannelDialog, { pluginId: plugin.id });
    const handlePluginDeletion = useCallback(() => confirmPluginDeletion(plugin)
        .then(() => deleteMutation.mutateAsync(plugin.id))
        .catch(noop), [plugin, deleteMutation]);
    const navigateToAuditLogs = () => {
        navigate('audit-logs');
    };
    if (deleteMutation.isSuccess) {
        return _jsx(Navigate, { to: "/plugins" });
    }
    return (_jsxs("div", { children: [_jsx(PageTitle, { title: (plugin === null || plugin === void 0 ? void 0 : plugin.name) || '', breadcrumbs: [{ title: 'Plugins', href: '/plugins' }], rightPanel: _jsxs(Group, { children: [_jsx(Tooltip, { title: "Copy plugin repository URL", children: _jsx(Button, { icon: copyIcon, onClick: () => configureCustomChannel(), ...assignTestId('copy-plugin-repository-url-button') }) }), _jsx(Tooltip, { title: "Change history", children: _jsx(Button, { icon: timeIcon, onClick: navigateToAuditLogs }) }), _jsx(Tooltip, { title: "Remove plugin", children: _jsx(Button, { icon: trashIcon, onClick: handlePluginDeletion, danger: true }) })] }) }), deleteMutation.isLoading && _jsx(Loader, {}), enableFetching && (_jsxs(_Fragment, { children: [_jsx(PluginInfo, { plugin: plugin }), _jsx(PluginVersions, { plugin: plugin })] }))] }));
}

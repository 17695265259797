import { createSelector } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { findApplicationRule, findSetting } from './profile-reducer-helpers';
const select = (state) => state.profileReducer.newProfiles;
const metadataSelect = (state) => state.profileReducer.metadata;
const immutableStateSelect = (state) => state.profileReducer.immutableProfiles;
export const getApplications = createSelector(select, state => state.applications);
export const getSettings = createSelector(select, state => state.settings);
export const getIdeSettings = createSelector(select, state => state.settings.ide);
export const getVmOptionsSettings = createSelector(select, state => state.settings.vmOptions);
export const getToolboxSettings = createSelector(select, state => {
    var _a;
    return (_a = state.settings.toolbox) === null || _a === void 0 ? void 0 : _a.map(it => {
        const { customSettings, ...restContent } = it.content;
        return {
            ...it,
            id: it.id || '',
            tools: it.tools || [],
            content: restContent
        };
    });
});
export const getMarketplaceSettings = createSelector(select, state => state.settings.marketplace);
export const getAutoinstalledToolsSettings = createSelector(select, state => state.settings.autoinstalledTools);
export const getProfile = createSelector(select, state => state);
export const getProfileTitle = createSelector(select, state => state.title);
export const getPlugins = createSelector(select, state => state.plugins);
export const getAutoInstalledPlugins = createSelector(select, state => state.plugins.autoInstalled);
export const getDisabledPlugins = createSelector(select, state => state.plugins.disabled);
export const getApplication = createSelector([select, (select, product) => product], (select, product) => select.applications.content.find(application => application.product === product));
export const isSettingChanged = createSelector([
    select,
    immutableStateSelect,
    (select, id, settingType) => [id, settingType]
], (state, immutableState, args) => {
    const [id, settingType] = args;
    return !isEqual(findSetting(immutableState, id, settingType), findSetting(state, id, settingType));
});
export const isApplicationRuleChanged = createSelector([select, immutableStateSelect, (select, id) => id], (state, immutableState, id) => {
    return !isEqual(findApplicationRule(immutableState, id), findApplicationRule(state, id));
});
export const isAppChanged = createSelector([select, immutableStateSelect, (select, app) => app], (state, immutableState, app) => {
    return !isEqual(immutableState.applications.content.find(x => x.product === app), state.applications.content.find(x => x.product === app));
});
export const isStateChanged = createSelector([select, immutableStateSelect], (state, immutableState) => {
    return !isEqual(immutableState, state);
});
export const getNotifications = createSelector(select, state => {
    var _a;
    return ((_a = state.restrictions) === null || _a === void 0 ? void 0 : _a.rules) || [];
});
export const isProfileStale = createSelector(metadataSelect, state => state.isProfileStale);
export const getNotification = createSelector([select, (select, id) => id], (select, id) => {
    if (id !== undefined) {
        return {
            ...select.applications.rules.find(rule => rule.id === id)
        };
    }
    return undefined;
});
export const getProfileSettings = createSelector(select, ({ id, title, description, isDefault }) => {
    return { id, title, description, isDefault };
});
export const getProfileConstituents = createSelector(select, state => state.constituents);
export const getImmutablePluginIds = createSelector(immutableStateSelect, state => {
    return [
        ...state.plugins.disabled.flatMap(x => x.content.map(y => y.pluginId)),
        ...state.plugins.autoInstalled.flatMap(x => x.content.map(y => y.pluginId))
    ];
});
export const getImmutableProfileConstituents = createSelector(immutableStateSelect, state => state.constituents);

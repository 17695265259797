import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import Button from '@jetbrains/ring-ui/components/button/button';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Select from '@jetbrains/ring-ui/components/select/select';
import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline';
import { getPluginsUrlFeed } from '@api/plugins';
import NavigationLink from '@app/common/navigation/navigation-link';
import CopiedCode from '@common/clipboard/copied-code/copied-code';
import { copyToClipboard } from '@common/clipboard/copy-to-clipboard';
import { ExternalLinks } from '@common/constants/constants';
import { useExistingPluginChannels } from '@common/queries/plugins';
import RingFieldsList from '@common/form/ring-fields-list';
import { assignTestId } from '@common/infra/tests/assign-test-id';
export default function ConfigureCustomChannelDialog({ onCloseAttempt, pluginId }) {
    const { data: channels, isLoading } = useExistingPluginChannels();
    const channelOptions = useMemo(() => (channels || []).map(channel => ({
        label: channel.name,
        key: channel.id
    })), [channels]);
    const [selectedChannelOption, setSelectedChannelOption] = useState(null);
    const currentChannelOption = selectedChannelOption || channelOptions[0];
    const url = useMemo(() => {
        const base = getPluginsUrlFeed();
        return `${base}?${pluginId ? `pluginId=${pluginId}&` : ''}channel=${(currentChannelOption === null || currentChannelOption === void 0 ? void 0 : currentChannelOption.label) || ''}`;
    }, [currentChannelOption, pluginId]);
    const copyAndClose = useCallback(() => {
        copyToClipboard(url);
        onCloseAttempt(undefined);
    }, [url, onCloseAttempt]);
    return (_jsxs(Dialog, { label: "Copy Plugin Repository URL", "data-test": "configure-custom-channel-dialog", show: true, onCloseAttempt: onCloseAttempt, trapFocus: true, children: [_jsx(Header, { children: "Copy Plugin Repository URL" }), _jsx(Content, { children: _jsxs(RingFieldsList, { children: [_jsx(Text, { info: true, children: _jsxs("div", { children: [_jsx("p", { children: "You can access plugins that belong to a specific channel within your IDE." }), _jsxs("p", { children: ["Select the required channel from the list, copy the plugin repository URL, and", ' ', _jsx(NavigationLink, { target: "_blank", href: ExternalLinks.JB_MANAGING_PLUGINS, children: "configure a custom plugin repository" }), ' ', "in your IDE."] })] }) }), isLoading ? (_jsx(LoaderInline, {})) : (_jsxs(_Fragment, { children: [_jsx(Select, { selectedLabel: "Channel", data: channelOptions, selected: selectedChannelOption || channelOptions[0], onSelect: setSelectedChannelOption, ...assignTestId('plugin-channel-selector') }), _jsx(CopiedCode, { text: url, ...assignTestId('plugin-channel-url') })] }))] }) }), _jsxs(Panel, { children: [_jsx(Button, { onClick: copyAndClose, primary: true, children: "Copy and close" }), _jsx(Button, { onClick: onCloseAttempt, ...assignTestId('cancel-dialog-button'), children: "Cancel" })] })] }));
}
